import axios from "axios";
import { AxiosInstance } from "axios";

const baseURL = process.env.VUE_APP_BASE_URL;

interface ResData<T> {
  code: number;
  data: T;
  msg: string;
}

class customRequest {
  instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL,
      timeout: 10000
    });

    // this.instance.defaults.headers.Route = "/login";
    this.instance.defaults.headers.ClientType = "PC";

    this.instance.interceptors.response.use(
      function (response) {
        if (response.data && response.data.code === 200) {
          return response.data;
        } else {
          return Promise.reject(response.data);
        }
      },
      function (error) {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        return Promise.reject(error);
      }
    );
  }

  post<T, Res>(url: string, data: T): Promise<ResData<Res>> {
    return this.instance.request({
      url,
      data,
      method: "post",
      headers: {
        Route: "/login",
        "Content-Type": "application/json"
      }
    });
  }
}

const HttpRequest = new customRequest();

export { HttpRequest };
