/* unplugin-vue-components disabled */import { defineComponent, ref } from "vue";
import zh from "element-plus/lib/locale/lang/zh-cn";
export default defineComponent({
  // components: { ElConfigProvider },
  setup() {
    const locale = ref(zh);
    onMounted(() => {
      nextTick().then(() => {
        // document.className = "dark";
        // document.documentElement.setAttribute("class", "dark");
      });
    });
    return {
      locale
    };
  }
});