/* unplugin-vue-components disabled */import { authentication } from "@/request/api";
export default defineComponent({
  name: "Home",
  setup() {
    const checked1 = ref(false);
    const a = reactive({
      a: true
    });
    authentication("/authentication", {
      password: "123456",
      type: "PC",
      username: "bjyc",
      id: 8
    }).then(res => {
      console.log(res.data.id);
    }).catch(err => {
      console.log(err);
    });
    return {
      a,
      checked1
    };
  }
});