import { HttpRequest } from "./index";
interface ResData<T> {
  code: number;
  data: T;
  msg: string;
}

interface Test {
  id?: number;
}
function authentication<T, Res>(url: string, data: T): Promise<ResData<Res>> {
  // data.id = 6;
  return HttpRequest.post(url, data);
}
// interface Test {
//   id?: number;
// }
// function authentication<T extends Test, Res>(
//   url: string,
//   data: T
// ): Promise<ResData<Res>> {
//   data.id = 6;
//   return HttpRequest.post(url, data);
// }

export { authentication };
